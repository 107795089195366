@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

#App {
    width: 100%;
    height: 100%;
}

#waves-el {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -100;
}

div#info-container {
    position: absolute;
    width: 100%;
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    margin-top: 2%;
    font-size: 150%;
}

div#links ul {
    padding: 0;
}

div#links ul li {
    list-style: none;
    display: inline-block;
    margin: 0.5%;
}

div#links a {
    position: relative;
    display: block;
    color: inherit;
    text-decoration: none;
}

div#links a:hover {
    transform: scale(1.3);
}

#picture {
    width: 7.5%;
    border-radius: 10%;
}