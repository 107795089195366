@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
html, body, #root {
  margin: 0;
  height: 100%;
}
#App {
    width: 100%;
    height: 100%;
}

#waves-el {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -100;
}

div#info-container {
    position: absolute;
    width: 100%;
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    margin-top: 2%;
    font-size: 150%;
}

div#links ul {
    padding: 0;
}

div#links ul li {
    list-style: none;
    display: inline-block;
    margin: 0.5%;
}

div#links a {
    position: relative;
    display: block;
    color: inherit;
    text-decoration: none;
}

div#links a:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

#picture {
    width: 7.5%;
    border-radius: 10%;
}
.popup-container {
    text-align: center;
    z-index: 100;
    position: absolute;
    margin: 0;
    top:  2.5%;
    left: 82.5%;
    border-radius: 1vmin;
    width: 15%;
    background-color: #191a19;
    color: #FBFAF5;
    font-family: 'Ubuntu', sans-serif;
}

.popup-shown {
    opacity: 1;
    transition: all 250ms linear;
}

.popup-hidden {
    opacity: 0;
    transition: all 250ms linear 2s;
}
