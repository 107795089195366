@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.popup-container {
    text-align: center;
    z-index: 100;
    position: absolute;
    margin: 0;
    top:  2.5%;
    left: 82.5%;
    border-radius: 1vmin;
    width: 15%;
    background-color: #191a19;
    color: #FBFAF5;
    font-family: 'Ubuntu', sans-serif;
}

.popup-shown {
    opacity: 1;
    transition: all 250ms linear;
}

.popup-hidden {
    opacity: 0;
    transition: all 250ms linear 2s;
}